module.exports = {
  // API_URL: "https://www.webhook.synx.ai",
  API_URL: "https://webhook.staging.synx.ai",
  // API_URL: "http://localhost:8000",
  APP_TRANSACTION_BASE_URL: "/app/api/transaction",
  APP_USER_BASE_URL: "/app/api/user",
  APP_GHL_BASE_URL: "/gohighlevel",
  APP_MB_BASE_URL: "/mindbody",
  APP_MT_BASE_URL: "/marianatek",
  APP_STRIPE_BASE_URL: "/app/api/stripe",
  APP_WEBHOOK_BASE_URL: "/mindbody",
  APP_OAUTH_URL: "/crm/oauth",
};
